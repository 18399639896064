/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useEffect } from 'react';

import { LinkBannerCompProps, LinkItems, LinkBannerProps } from './LinkBanner.types';
import { tags, headingType, size } from '../../utils/enums/enums';
import { StyledLinkBannerComp, StyledLinkBanner } from './LinkBanner.styles';
import { LinksCollection } from '../LinksCollection';
import { Heading } from '../../atoms/Heading';
import { Icon } from '../../atoms/Icon';
import clsx from 'clsx';

const LinkBannerComp: FC<LinkBannerCompProps> = ({
  linkBannerData,
  trackingProperties,
  customClassName = '',
  noOfColumns,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const accordionToggleHandler = () => {
    if (window?.matchMedia('(min-width: 767px)')?.matches) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    accordionToggleHandler();
    //removed resize handler from here as on real devices/browserstack resize handle gets called on mobile scroll which closes the accordion unexpectedly
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    // Check if the key pressed is 'Enter' or if the event type is 'change' or if the keyCode is 13 (which is the keyCode for 'Enter')
    if (event.key === 'Enter' || event.type === 'change' || event.keyCode === 13) {
      // Toggle the value of isOpen state variable
      setIsOpen(!isOpen);
    }
  };

  return (
    <StyledLinkBannerComp
      data-component-name="m-ui-library-LinkBanner"
      data-testid="ui-library-LinkBanner"
      className={`lb ${customClassName}`}
    >
      <div
        className={clsx(
          `lb__container`,
          `${noOfColumns === 4 && 'lb_container_four_columns'}`,
          `${noOfColumns === 1 && 'lb_container_single_column'}`
        )}
      >
        {linkBannerData.header && (
          <Heading
            element={linkBannerData.columnHeaderTag || tags.h4}
            variation={headingType.subtitle}
            titleText={linkBannerData.header || ''}
            fontSize={(linkBannerData.columnFontSize as size) || ('m' as size.medium)}
            customClass="d-md-flex d-none pb-2 mb-0"
            disableCustomClickTrack
            tabIndex={linkBannerData.header ? 0 : -1}
          />
        )}
        <div
          className="lb__button d-md-none d-flex p-md-0"
          role="button"
          tabIndex={0}
          onClick={() => setIsOpen(!isOpen)}
          onKeyDown={handleKeyDown}
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          {linkBannerData.header && (
            <Heading
              element={linkBannerData.headerTag || linkBannerData.columnHeaderTag || tags.h4}
              variation={headingType.subtitle}
              titleText={linkBannerData.header || ''}
              fontSize={(linkBannerData.columnFontSize as size) || ('m' as size.medium)}
              disableCustomClickTrack
            />
          )}
          <Icon iconClass={isOpen ? 'icon-arrow-up' : 'icon-arrow-down'} />
        </div>
        {isOpen && (
          <LinksCollection
            trackingProperties={trackingProperties}
            linkListData={linkBannerData?.linkItems}
            listCollectionClassname="lb__linkCollection"
          />
        )}
      </div>
    </StyledLinkBannerComp>
  );
};

export const LinkBanner = (props: LinkBannerProps): JSX.Element => {
  const [windowSize, setWindowSize] = useState(0);
  // const customAttributes = props?.model?.trackingProperties?.enableScrollingBehavior;
  let noOfColumns = +props?.model?.noOfColumns;

  useEffect(() => {
    setWindowSize(window.innerWidth);
    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //Forming a single array items from multiple arrays for mobile
  const mobileLinkItems = props?.model?.columnDetails?.reduce(
    (result: unknown[], current: { linkItems: LinkItems[] }) => {
      current?.linkItems?.length && result.push(...current.linkItems);
      return result;
    },
    []
  );

  const mobileLinkBannerData = [
    {
      header: props?.model?.linkBannerHeader,
      headerTag: props?.model?.headerTag,
      headerFontSize: props?.model?.headerFontSize,
      linkItems: mobileLinkItems,
    },
  ];

  const middleIndex = Math.floor(props?.model?.columnDetails?.length / 2);

  //Merging all arrays into two arrays for tablet based on middle index

  const tabletFirstColumn = props?.model?.columnDetails
    ?.slice(0, middleIndex)
    ?.reduce((result: unknown[], current: { linkItems: LinkItems[] }) => {
      current?.linkItems?.length && result.push(...current.linkItems);
      return result;
    }, []);

  const tabletSecondColumn = props?.model?.columnDetails
    ?.slice(middleIndex)
    ?.reduce((result: unknown[], current: { linkItems: LinkItems[] }) => {
      current?.linkItems?.length && result.push(...current.linkItems);
      return result;
    }, []);

  const tabLinkBannerData = [
    { header: '', linkItems: tabletFirstColumn },
    { header: '', linkItems: tabletSecondColumn },
  ];

  // checking window size for customising data incase of resize or change from potrait to landscape
  const handleResize = () => {
    if (typeof window !== 'undefined') {
      setWindowSize(window.innerWidth);
    }
  };

  //Based on the flag sending appropriate data for view port and incase of no flag or flag with false value making it process default value
  const fetchlinkBannerData = () => {
    if (props?.model?.linkBannerHeader) {
      if (Number(windowSize) < 768) {
        noOfColumns = 1;
        return mobileLinkBannerData;
      } else if (Number(windowSize) >= 768 && Number(windowSize) <= 992) {
        noOfColumns = 2;
        return tabLinkBannerData;
      } else {
        return props?.model?.columnDetails;
      }
    } else {
      return props?.model?.columnDetails;
    }
  };
  const linkBannerData = fetchlinkBannerData();

  return (
    <StyledLinkBanner
      className={clsx(props?.model?.styleclass ? props?.model?.styleclass : '')}
      data-testid="link-banner"
      noOfElements={noOfColumns}
      // {...customAttributes}
    >
      {props?.model?.linkBannerHeader && Number(windowSize) >= 768 && (
        <div className="lb-heading" tabIndex={0} role="heading" aria-level={1}>
          <Heading
            titleText={props?.model?.linkBannerHeader}
            element={(props?.model?.headerTag as tags) || ('h3' as tags.h3)}
            variation={headingType.title}
            customClass={'container'}
            fontSize={
              (props?.model?.headerFontSize as size.small | size.medium | size.large | size.extraSmall | undefined) ||
              ('m' as size.medium)
            }
          />
        </div>
      )}
      <div className="container px-0 lb__wrapper">
        {linkBannerData?.map((el: any) => {
          return (
            <LinkBannerComp
              linkBannerData={el}
              trackingProperties={props?.model?.trackingProperties}
              customClassName={`col-md-${12 / noOfColumns} col-12 ${
                props?.model?.hideColumDividers === true ? 'lb_without_dividers' : ''
              }`}
              noOfColumns={noOfColumns}
            />
          );
        })}
      </div>
    </StyledLinkBanner>
  );
};
